$small-width: 425px;
$medium-width: 750px;
$large-width: 1024px;

@mixin small {
  @media (min-width: #{$small-width}) {
    @content;
  }
}

@mixin medium {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

$background-color-dark: #75d6c7;
$text-color-dark: black;
$text-color-h2: #68c9ba;
$text-link: #5db2a6;

body {
  font-family: "futura-pt";
  font-weight: 300;
  letter-spacing: 0.3px;
}

.navbar {
  background-color: $background-color-dark;
  opacity: 0.8;
  font-weight: 500;
  z-index: 100;
  padding-right: 1rem;
  padding-left: 1rem;

  a:hover {
    color: white;
  }

  .nav-right {
    a {
      font-size: 14px;
    }
  }
  button {
    border: none;
    background-color: $background-color-dark;
  }
  button:hover {
    background-color: transparent;
  }

  .field.is-grouped {
    justify-content: center;
    flex-wrap: no-wrap;

    .control {
      flex: 100%;
      text-align: center;
      margin: 0;
      padding: 5px;
      padding-left: 30px;

      a {
        color: #4a4a4a;
      }
      a:hover {
        color: white;
      }
    }
  }
}

.navbar-menu.is-active {
  background-color: $background-color-dark;
}

.notification,
.notification:not(:last-child) {
  background-color: lightslategrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0%;
  margin: 0;
  font-family: sans-serif;
  p {
    color: white;
    padding-right: 0.5rem;
    font-size: 0.9rem;
  }
}

.work-container {
  margin: auto;
  box-sizing: border-box;
  padding-top: 1rem;
}

.grid-area-container {
  // border: 10px solid red;
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: center;
}

.grid-container-outer {
  display: flex;
  width: 80%;
  margin: 5rem 2rem 2rem 2rem;
  @include medium {
    width: 40%;
  }
}

.grid-container {
  flex: 0 0 100%;
  flex-wrap: wrap;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.grid-image-block {
  box-sizing: border-box;
  flex: 0 0 100%;
  height: 300px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: border-box;

  &:hover {
    .grid-hover {
      box-sizing: border-box;
      height: 300px;
      background-color: #75d6c7;
      opacity: 0.2;
    }
  }
}

.grid-subtext {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .caption-title {
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    margin: 1.5rem 0 0 0;
    p {
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 1px;
      margin: 0;
      font-weight: 400;
    }
  }
  .caption-subtext {
    display: flex;
    justify-content: center;
    margin: 0;
  }
}

.showcase-container {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid grey;
  padding: 3rem;
  justify-content: space-around;
  align-items: center;
}

.showcase-image-block {
  margin: 2rem;
  height: 400px;
  flex: 0 0 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: border-box;

  @include medium {
    flex: 0 0 45%;
  }
}

.showcase-text-container {
  flex: 0 0 100%;

  .showcase-title {
    h1 {
      letter-spacing: 0.6px;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  .showcase-position {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.9rem;
  }

  @include medium {
    flex: 0 0 40%;
  }
}

.footer-container-inner {
  display: flex;
  justify-content: flex-end;
  margin: 5rem 5rem 6rem 5rem;
}

.footer-item {
  margin: 0.5rem;
}

.resume-container {
  margin: auto;
  // border: 10px solid red;
}

.resume-body-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 5% 2rem 5%;

  @include medium {
    flex-wrap: nowrap;
    margin: 0 15% 10rem 15%;
  }

  h1 {
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    font-size: 2rem;
  }
  h2 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 1.2px;
    color: $text-color-h2;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 1.2rem;
    margin: 1.8rem 0 0 0;
  }
  h4 {
    text-transform: uppercase;
    font-weight: 300;
    margin: 1rem 0 0 0;
  }
  hr {
    margin: 2rem;
    color: gray;
  }
  p {
    line-height: 1.4rem;
    margin: 0.3rem 0 0 0;
  }
  a {
    text-decoration: none;
    color: $text-link;
  }
  ul {
    margin: 0 0 0.5rem 0;
  }
  li {
    margin: 0.5rem 0 0.5rem 0;
  }
  .picture {
    text-align: center;
  }
  .header-with-icon {
    display: flex;
    align-items: center;
    img {
      margin-right: 0.3rem;
      max-width: 1.5rem;
    }
  }
  .address-block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    span {
      flex: 0 0 100%;
    }
    a {
      display: flex;
      text-decoration: none;
      align-items: center;
      color: black;
      line-height: 2rem;
      img {
        margin: 0 0.4rem 0 0.4rem;
        max-width: 1.5rem;
      }
    }
  }
  .sidebar-container {
    flex: 0 0 100%;
    padding: 3rem 2rem 0 2rem;
    margin-top: 3rem;
    border: none;
    @include medium {
      flex: 0 0 30%;
      border-right: 1px solid #a5a5a5;
    }
  }
  .right-panel-container {
    flex: 0 0 100%;
    padding: 1.5rem;
    @include medium {
      flex: 0 0 65%;
      padding: 1rem 0 0 0;
      margin-top: 3.5rem;
      padding: 1.5rem 3rem 3rem 3rem;
    }
  }
}
